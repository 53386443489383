import React from "react"

import Link from "../utils/link"

import "./footer.scss"

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__details">
          <div className="footer__contact">
            <Link to="tel:+61390709832" className="footer__contact">
              +61 3 9070 9832
            </Link>
            <br />
            <Link
              to="mailto:mail@stuartfamilylawyers.com.au"
              className="footer__contact"
            >
              mail@stuartfamilylawyers.com.au
            </Link>
          </div>
          <div className="footer__address">
            Level 11, 456 Lonsdale Street
            <br />
            Melbourne VIC 3000
          </div>
        </div>
        <p className="footer__title">
          Liability limited by a scheme approved under Professional Standards Legislation.
        </p>
        <div className="footer__copyright">
          © 2024 Stuart Family Lawyers. Design by <Link to='https://lbdstudios.com.au'>LBD Studios</Link> + <Link to='https://atollon.com.au'>Atollon</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
